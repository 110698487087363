import React from 'react';
import '../App.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <div>
        <h2>Einleitung</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als „Daten“ bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
          zusammenfassend bezeichnet als „Onlineangebot“).
        </p>
        <p>Stand: 25. Juli 2019</p>
      </div>
      <div>
        <div>
          <h2>Inhaltsübersicht</h2>
          <ul>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m14"
                data-et-has-event-already="true"
              >
                Einleitung
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m3"
                data-et-has-event-already="true"
              >
                Verantwortlicher
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#mOverview"
                data-et-has-event-already="true"
              >
                Übersicht der Verarbeitungen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m13"
                data-et-has-event-already="true"
              >
                Maßgebliche Rechtsgrundlagen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m27"
                data-et-has-event-already="true"
              >
                Sicherheitsmaßnahmen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m25"
                data-et-has-event-already="true"
              >
                Übermittlung und Offenbarung von personenbezogenen Daten
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m24"
                data-et-has-event-already="true"
              >
                Datenverarbeitung in Drittländern
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m134"
                data-et-has-event-already="true"
              >
                Einsatz von Cookies
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m317"
                data-et-has-event-already="true"
              >
                Kommerzielle und geschäftliche Leistungen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m367"
                data-et-has-event-already="true"
              >
                Registrierung und Anmeldung
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m451"
                data-et-has-event-already="true"
              >
                Single-Sign-On-Anmeldung
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m104"
                data-et-has-event-already="true"
              >
                Blogs und Publikationsmedien
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m182"
                data-et-has-event-already="true"
              >
                Kontaktaufnahme
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m391"
                data-et-has-event-already="true"
              >
                Kommunikation via Messenger
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m408"
                data-et-has-event-already="true"
              >
                Umfragen und Befragungen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m225"
                data-et-has-event-already="true"
              >
                Bereitstellung des Onlineangebotes und Webhosting
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m17"
                data-et-has-event-already="true"
              >
                Newsletter und Breitenkommunikation
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m237"
                data-et-has-event-already="true"
              >
                Gewinnspiele und Wettbewerbe
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m263"
                data-et-has-event-already="true"
              >
                Webanalyse und Optimierung
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m264"
                data-et-has-event-already="true"
              >
                Onlinemarketing
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m135"
                data-et-has-event-already="true"
              >
                Affiliate-Programme und Affiliate-Links
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m136"
                data-et-has-event-already="true"
              >
                Präsenzen in sozialen Netzwerken
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m328"
                data-et-has-event-already="true"
              >
                Plugins und eingebettete Funktionen sowie Inhalte
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m12"
                data-et-has-event-already="true"
              >
                Löschung von Daten
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m15"
                data-et-has-event-already="true"
              >
                Änderungen und Aktualisierungen der Datenschutzerklärung
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m10"
                data-et-has-event-already="true"
              >
                Rechte der betroffenen Personen
              </a>
            </li>
            <li>
              <a
                href="https://progressive-mind.com/Datenschutzbestimmungen/#m42"
                data-et-has-event-already="true"
              >
                Begriffsdefinitionen
              </a>
            </li>
          </ul>
          <h2 id="m3">Verantwortlicher</h2>
          <p>
            Progressive Mind GmbH
            <br />
            Geschäftsführer:
            <br />
            Arno Hoffrichter, Florian Wessling
          </p>
          <p>
            Tesdorpfstr. 21
            <br />
            20148 Hamburg
            <br />
            Deutschland
          </p>
          <p>
            <a
              href="mailto:info@progressive-mind.com"
              target="_top"
              rel="noopener noreferrer"
            >
              info@progressive-mind.com
            </a>
            <br />
            Tel:{' '}
            <a
              href="tel:+4940468967680"
              title="Progressive Mind Marketing anrufen"
              target="_top"
              rel="noopener noreferrer"
            >
              +49 40 46 89 67 68 0
            </a>
          </p>
          <p>
            <strong>Impressum</strong>:{' '}
            <a href="https://progressive-mind.com/impressum%C2%A0">
              https://progressive-mind.com/impressum{' '}
            </a>
          </p>
          <ul></ul>
          <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
            und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
            betroffenen Personen.
          </p>
          <h3>Arten der verarbeiteten Daten</h3>
          <ul>
            <li>Bestandsdaten (Namen, Adressen, etc.).</li>
            <li>Inhaltsdaten (Texteingaben, Fotografien, Videos).</li>
            <li>Kontaktdaten (E-Mail, Telefonnummern).</li>
            <li>
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              Nutzungsdaten (besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <li>
              Sozialdaten (Daten, die dem Sozialgeheimnis (§ 35 SGB I)
              unterliegen und z.B. von Sozialversicherungsträgern,
              Sozialhilfeträger oder Versorgungsbehörden verarbeitet werden.).
            </li>
            <li>Standortdaten.</li>
            <li>
              Vertragsdaten (Vertragsgegenstand, Laufzeit, Kundenkategorie).
            </li>
            <li>
              Zahlungsdaten (Bankverbindungen, Rechnungen, Zahlungshistorie).
            </li>
          </ul>
          <h3>Kategorien betroffener Personen</h3>
          <ul>
            <li>
              Beschäftigte (Angestellte, Bewerber, Ehemalige Mitarbeiter).
            </li>
            <li>Geschäfts- und Vertragspartner.</li>
            <li>Interessenten.</li>
            <li>Kommunikationspartner.</li>
            <li>Kunden.</li>
            <li>Nutzer (Websitebesucher, Nutzer von Onlinediensten).</li>
            <li>Teilnehmer von Gewinnspielen und Wettbewerben.</li>
          </ul>
          <h3>Zwecke der Verarbeitung</h3>
          <ul>
            <li>Affiliate-Nachverfolgung.</li>
            <li>Anmeldeverfahren.</li>
            <li>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>Besuchsaktionsauswertung.</li>
            <li>Büro- und Organisationsverfahren.</li>
            <li>Direktmarketing (per E-Mail oder postalisch).</li>
            <li>
              Durchführung von Gewinnspielen und Wettbewerben (Conducting
              sweepstakes and contests).
            </li>
            <li>Feedback (Sammeln von Feedback, z.B. via Formular).</li>
            <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>
              Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen).
            </li>
            <li>Profiling (Erstellen von Profilen der Nutzer).</li>
            <li>Remarketing.</li>
            <li>
              Reichweitenmessung (Zugriffsstatistiken, Erkennung wiederkehrender
              Besucher).
            </li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>
              Tracking (Interessens-/verhaltensbezogenes Profiling, Cookies).
            </li>
            <li>Vertragliche Leistungen und Service.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
          </ul>
          <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Im Folgenden teilen wir die Rechtsgrundlagen der
            Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
            personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
            zusätzlich zu den Regelungen der DSGVO die nationalen
            Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
            können.
          </p>
          <ul>
            <li>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> –
              Die betroffene Person hat ihre Einwilligung zu der Verarbeitung
              der sie betreffenden personenbezogenen Daten für einen oder
              mehrere bestimmte Zwecke gegeben.
            </li>
            <li>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b. DSGVO)
              </strong>{' '}
              – Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
              Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
              betroffenen Person erfolgen.
            </li>
            <li>
              <strong>
                Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)
              </strong>{' '}
              – Die Verarbeitung ist zur Erfüllung einer rechtlichen
              Verpflichtung erforderlich, der der Verantwortliche unterliegt.
            </li>
            <li>
              <strong>
                Schutz lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit. d.
                DSGVO)
              </strong>{' '}
              – Die Verarbeitung ist erforderlich, um lebenswichtige Interessen
              der betroffenen Person oder einer anderen natürlichen Person zu
              schützen.
            </li>
            <li>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)
              </strong>{' '}
              – Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </li>
          </ul>
          <p>
            <strong>Nationale Datenschutzregelungen in Deutschland</strong>:
            Zusätzlich zu den Datenschutzregelungen der
            Datenschutz-Grundverordnung gelten nationale Regelungen zum
            Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz
            zum Schutz vor Missbrauch personenbezogener Daten bei der
            Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
            insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
            Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
            Kategorien personenbezogener Daten, zur Verarbeitung für andere
            Zwecke und zur Übermittlung sowie automatisierten
            Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
            Weiteren regelt es die Datenverarbeitung für Zwecke des
            Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
            auf die Begründung, Durchführung oder Beendigung von
            Beschäftigungsverhältnissen sowie die Einwilligung von
            Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
            Bundesländer zur Anwendung gelangen.
          </p>
          <ul></ul>
          <h2 id="m27">Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>
          <p>
            <strong>Kürzung der IP-Adresse</strong>: Sofern es uns möglich ist
            oder eine Speicherung der IP-Adresse nicht erforderlich ist, kürzen
            wir oder lassen Ihre IP-Adresse kürzen. Im Fall der Kürzung der
            IP-Adresse, auch als „IP-Masking“ bezeichnet, wird das letzte
            Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht
            (die IP-Adresse ist in diesem Kontext eine einem Internetanschluss
            durch den Online-Zugangs-Provider individuell zugeordnete Kennung).
            Mit der Kürzung der IP-Adresse soll die Identifizierung einer Person
            anhand ihrer IP-Adresse verhindert oder wesentlich erschwert werden.
          </p>
          <p>
            <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser
            Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
            SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen
            an dem Präfix https:// in der Adresszeile Ihres Browsers.
          </p>
          <h2 id="m25">
            Übermittlung und Offenbarung von personenbezogenen Daten
          </h2>
          <p>
            Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
            vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
            selbstständige Organisationseinheiten oder Personen übermittelt oder
            sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser
            Daten können z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen,
            mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten
            und Inhalten, die in eine Webseite eingebunden werden, gehören. In
            solchen Fall beachten wir die gesetzlichen Vorgaben und schließen
            insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
            Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
          </p>
          <p>
            <strong>Datenübermittlung innerhalb der Organisation</strong>: Wir
            können personenbezogene Daten an andere Stellen innerhalb unserer
            Organisation übermitteln oder ihnen den Zugriff auf diese Daten
            gewähren. Sofern diese Weitergabe zu administrativen Zwecken
            erfolgt, beruht die Weitergabe der Daten auf unseren berechtigten
            unternehmerischen und betriebswirtschaftlichen Interessen oder
            erfolgt, sofern sie Erfüllung unserer vertragsbezogenen
            Verpflichtungen erforderlich ist oder wenn eine Einwilligung der
            Betroffenen oder eine gesetzliche Erlaubnis vorliegt.
          </p>
          <h2 id="m24">Datenverarbeitung in Drittländern</h2>
          <p>
            Sofern wir Daten in einem Drittland (d.h., außerhalb der
            Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
            verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von
            Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an
            andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies
            nur im Einklang mit den gesetzlichen Vorgaben.
          </p>
          <p>
            Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
            gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir
            die Daten nur in Drittländern mit einem anerkannten
            Datenschutzniveau, zu denen die unter dem „Privacy-Shield“
            zertifizierten US-Verarbeiter gehören, oder auf Grundlage besonderer
            Garantien, wie z.B. vertraglicher Verpflichtung durch sogenannte
            Standardschutzklauseln der EU-Kommission, des Vorliegens von
            Zertifizierungen oder verbindlicher interner
            Datenschutzvorschriften, verarbeiten (Art. 44 bis 49 DSGVO,
            Informationsseite der EU-Kommission:{' '}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).
            </a>
          </p>
          <div>
            <ul></ul>
          </div>
          <h2 id="m134">Einsatz von Cookies </h2>
          <p>
            Als „Cookies“ werden kleine Dateien bezeichnet, die auf Geräten der
            Nutzer gespeichert werden. Mittels Cookies können unterschiedliche
            Angaben gespeichert werden. Zu den Angaben können z.B. die
            Spracheinstellungen auf einer Webseite, der Loginstatus, ein
            Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören.
          </p>
          <p>
            Cookies werden im Regelfall auch dann eingesetzt, wenn die
            Interessen eines Nutzers oder sein Verhalten (z.B. Betrachten
            bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen
            Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile
            dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren
            potentiellen Interessen entsprechen. Dieses Verfahren wird auch als
            „Tracking“, d.h., Nachverfolgung der potentiellen Interessen der
            Nutzer bezeichnet. Zu dem Begriff der Cookies zählen wir ferner
            andere Technologien, die die gleichen Funktionen wie Cookies
            erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer
            Onlinekennzeichnungen gespeichert werden, auch als „Nutzer-IDs“
            bezeichnet).
          </p>
          <p>
            Soweit wir Cookies oder „Tracking“-Technologien einsetzen,
            informieren wir Sie gesondert in unserer Datenschutzerklärung.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen: </strong>Auf welcher
            Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von
            Cookies verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung
            bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies
            einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten
            die erklärte Einwilligung. Andernfalls werden die mithilfe von
            Cookies verarbeiteten Daten auf Grundlage unserer berechtigten
            Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
            Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der
            Einsatz von Cookies erforderlich ist, um unsere vertraglichen
            Verpflichtungen zu erfüllen.
          </p>
          <p>
            <strong>Widerruf und Widerspruch (Opt-Out): </strong>Unabhängig
            davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder
            gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit,
            eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
            Daten durch Cookie-Technologien zu widersprechen (zusammenfassend
            als „Opt-Out“ bezeichnet).
          </p>
          <p>
            Sie können Ihren Widerspruch zunächst mittels der Einstellungen
            Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies
            deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
            Onlineangebotes eingeschränkt werden kann).
          </p>
          <p>
            Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des
            Onlinemarketings kann mittels einer Vielzahl von Diensten, vor allem
            im Fall des Trackings, über die US-amerikanische Seite{' '}
            <a
              href="http://www.aboutads.info/choices/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.aboutads.info/choices/
            </a>{' '}
            oder die EU-Seite{' '}
            <a
              href="http://www.youronlinechoices.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.youronlinechoices.com/
            </a>{' '}
            oder generell auf{' '}
            <a
              href="http://optout.aboutads.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://optout.aboutads.info
            </a>{' '}
            erklärt werden.
          </p>
          <p>
            <strong>
              Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung
            </strong>
            : Bevor wir Daten im Rahmen der Nutzung von Cookies verarbeiten oder
            verarbeiten lassen, bitten wir die Nutzer um eine jederzeit
            widerrufbare Einwilligung. Bevor die Einwilligung nicht
            ausgesprochen wurde, werden allenfalls Cookies eingesetzt, die für
            den Betrieb unseres Onlineangebotes erforderlich sind. Deren Einsatz
            erfolgt auf der Grundlage unseres Interesses und des Interesses der
            Nutzer an der erwarteten Funktionsfähigkeit unseres Onlineangebotes.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
          </ul>
          <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
          <p>
            Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
            Kunden und Interessenten (zusammenfassend bezeichnet als
            „Vertragspartner“) im Rahmen von vertraglichen und vergleichbaren
            Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen
            der Kommunikation mit den Vertragspartnern (oder vorvertraglich),
            z.B., um Anfragen zu beantworten.
          </p>
          <p>
            Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen
            Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit
            diesen Angaben einhergehenden Verwaltungsaufgaben sowie der
            unternehmerischen Organisation. Die Daten der Vertragspartner geben
            wir im Rahmen des geltenden Rechts nur insoweit an Dritte weiter,
            als dies zu den vorgenannten Zwecken oder zur Erfüllung gesetzlicher
            Pflichten erforderlich ist oder mit Einwilligung der Vertragspartner
            erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
            sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und
            Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über
            weitere Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden
            die Vertragspartner im Rahmen dieser Datenschutzerklärung
            informiert.
          </p>
          <p>
            Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen
            wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B.
            in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben)
            bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
          </p>
          <p>
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
            vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4
            Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
            werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung
            aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10
            Jahre). Daten, die uns im Rahmen eines Auftrags durch den
            Vertragspartner offengelegt wurden, löschen wir entsprechend den
            Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
          </p>
          <p>
            Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
            Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und
            den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der
            jeweiligen Drittanbieter oder Plattformen.
          </p>
          <p>
            <strong>Kundenkonto</strong>: Vertragspartner können innerhalb
            unseres Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw.
            Nutzerkonto, kurz „Kundenkonto“). Falls die Registrierung eines
            Kundenkontos erforderlich ist, werden Vertragspartner hierauf ebenso
            hingewiesen wie auf die für die Registrierung erforderlichen
            Angaben. Die Kundenkonten sind nicht öffentlich und können von
            Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung
            sowie anschließender Anmeldungen und Nutzungen des Kundenkontos
            speichern wir die IP-Adressen der Kunden nebst den
            Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
            Missbrauch des Kundenkontos vorbeugen zu können.
          </p>
          <p>
            Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das
            Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren
            Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt
            den Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos zu
            sichern.
          </p>
          <p>
            <strong>Wirtschaftliche Analysen und Marktforschung</strong>: Aus
            betriebswirtschaftlichen Gründen und um Markttendenzen, Wünsche der
            Vertragspartner und Nutzer erkennen zu können, analysieren wir die
            uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen,
            etc., wobei in die Gruppe der betroffenen Personen Vertragspartner,
            Interessenten, Kunden, Besucher und Nutzer unseres Onlineangebotes
            fallen können.
          </p>
          <p>
            Die Analysen erfolgen zum Zweck betriebswirtschaftlicher
            Auswertungen, des Marketings und der Marktforschung (z.B. zur
            Bestimmung von Kundengruppen mit unterschiedlichen Eigenschaften).
            Dabei können wir, sofern vorhanden, die Profile von registrierten
            Nutzern samt ihrer Angaben, z.B. zu in Anspruch genommenen
            Leistungen, berücksichtigen. Die Analysen dienen alleine uns und
            werden nicht extern offenbart, sofern es sich nicht um anonyme
            Analysen mit zusammengefassten, also anonymisierten Werten handelt.
            Ferner nehmen wir Rücksicht auf die Privatsphäre der Nutzer und
            verarbeiten die Daten zu den Analysezwecken möglichst pseudonym und,
            sofern machbar, anonym (z.B. als zusammengefasste Daten).
          </p>
          <p>
            <strong>Onlineshop und E-Commerce</strong>: Wir verarbeiten die
            Daten unserer Kunden, um ihnen die Auswahl, den Erwerb, bzw. die
            Bestellung der gewählten Produkte, Waren sowie verbundener
            Leistungen, als auch deren Bezahlung und Zustellung, bzw. Ausführung
            zu ermöglichen.
          </p>
          <p>
            Die erforderlichen Angaben sind als solche im Rahmen des Bestell-
            bzw. vergleichbaren Erwerbsvorgangs gekennzeichnet und umfassen die
            zur Auslieferung, bzw. Zurverfügungstellung und Abrechnung
            benötigten Angaben sowie Kontaktinformationen, um etwaige
            Rücksprache halten zu können.
          </p>
          <p>
            <strong>Agenturdienstleistungen</strong>: Wir verarbeiten die Daten
            unserer Kunden im Rahmen unserer vertraglichen Leistungen, zu denen
            z.B. konzeptionelle und strategische Beratung, Kampagnenplanung,
            Software- und Designentwicklung/-beratung oder -pflege, Umsetzung
            von Kampagnen und Prozessen, Handling, Serveradministration,
            Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören
            können.
          </p>
          <p>
            <strong>Bildungs- und Schulungsleistungen</strong>: Wir verarbeiten
            die Daten der Teilnehmer unserer Bildungs- und Schulungsangebote
            (einheitlich bezeichnet als „Aus- und Fortzubildende“), um ihnen
            gegenüber unsere Schulungsleistungen erbringen zu können. Die
            hierbei verarbeiteten Daten, die Art, der Umfang, der Zweck und die
            Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem
            zugrundeliegenden Vertrags- und Schulungsverhältnis. Zu den
            Verarbeitungsformen gehören auch die Leistungsbewertung und die
            Evaluation unserer Leistungen sowie jener der Lehrenden.
          </p>
          <p>
            In Rahmen unserer Tätigkeit können wir ferner besondere Kategorien
            von Daten, hier insbesondere Angaben zur Gesundheit der Aus- und
            Fortzubildenden sowie Daten, aus denen die ethnische Herkunft,
            politische Meinungen, religiöse oder weltanschauliche Überzeugungen
            hervorgehen, verarbeiten. Hierzu holen wir, sofern erforderlich,
            eine ausdrückliche Einwilligung der Aus- und Fortzubildenden ein und
            verarbeiten die besonderen Kategorien von Daten ansonsten nur, wenn
            es zur Erbringung der Schulungsleistungen, zu Zwecken der
            Gesundheitsvorsorge, des Sozialschutzes oder des Schutzes
            lebensnotwendiger Interessen der Aus- und Fortzubildenden
            erforderlich ist.
          </p>
          <p>
            Sofern es für unsere Vertragserfüllung, zum Schutz lebenswichtiger
            Interessen oder gesetzlich erforderlich ist, bzw. eine Einwilligung
            der Aus- und Fortzubildenden vorliegt, offenbaren oder übermitteln
            wir die Daten der Aus- und Fortzubildenden unter Beachtung der
            berufsrechtlichen Vorgaben an Dritte oder Beauftragte, wie z.B.
            Behörden oder im Bereich der IT, der Büro- oder vergleichbarer
            Dienstleistungen.
          </p>
          <p>
            <strong>Publikationstätigkeit</strong>: Wir verarbeiten die Daten
            von unseren Kontaktpartnern, Interviewpartnern sowie sonstigen
            Personen, die Gegenstand unserer publizistischen, redaktionellen und
            journalistischen sowie verwandter Tätigkeiten sind. In diesem
            Zusammenhang verweisen wir auf die Geltung von Schutzvorschriften
            der Meinungs- und Pressefreiheit gem. Art. 85 DSGVO in Verbindung
            mit den jeweiligen nationalen Gesetzen. Die Verarbeitung dient der
            Erfüllung unserer Auftragstätigkeiten und findet im Übrigen
            insbesondere auf Grundlage des Interesses der Allgemeinheit an
            Informations- und Medienangeboten statt.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Zahlungsdaten (Bankverbindungen, Rechnungen,
              Zahlungshistorie), Kontaktdaten (E-Mail, Telefonnummern),
              Vertragsdaten (Vertragsgegenstand, Laufzeit, Kundenkategorie),
              Nutzungsdaten (besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta-/Kommunikationsdaten (Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Interessenten, Geschäfts-
              und Vertragspartner, Kunden.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
              und Service, Kontaktanfragen und Kommunikation, Büro- und
              Organisationsverfahren, Verwaltung und Beantwortung von Anfragen,
              Sicherheitsmaßnahmen, Besuchsaktionsauswertung,
              Interessenbasiertes und verhaltensbezogenes Marketing, Profiling
              (Erstellen von Profilen der Nutzer).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m367">Registrierung und Anmeldung</h2>
          <p>
            Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
            werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und
            zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
            vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten
            Daten gehören insbesondere die Login-Informationen (Name, Passwort
            sowie eine E-Mail-Adresse). Die im Rahmen der Registrierung
            eingegebenen Daten werden für die Zwecke der Nutzung des
            Nutzerkontos und dessen Zwecks verwendet.
          </p>
          <p>
            Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
            sind, wie z.B. technische Änderungen, per E-Mail informiert werden.
            Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im
            Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen
            Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern, ihre Daten
            bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind
            berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten
            des Nutzers unwiederbringlich zu löschen.
          </p>
          <p>
            Im Rahmen der Inanspruchnahme unserer Registrierungs- und
            Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir
            die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
            Speicherung erfolgt auf Grundlage unserer berechtigten Interessen
            als auch jener der Nutzer an einem Schutz vor Missbrauch und
            sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
            erfolgt grundsätzlich nicht, es sei denn, sie ist zur Verfolgung
            unserer Ansprüche erforderlich oder es besteht hierzu besteht eine
            gesetzliche Verpflichtung.
          </p>
          <p>
            <strong>Online-Forum</strong>: Die Teilnahme am Forum setzt eine
            Registrierung voraus, bei der, vorbehaltlich anderer Angaben im
            Registrierungsformular, ein oder Ihr Name, ein Kennwort und die
            E-Mail-Adresse, an welche die Zugangsdaten versendet werden,
            angegeben werden müssen. Aus Gründen der Sicherheit sollte das
            Kennwort dem Stand der Technik entsprechen, also kompliziert sein
            (hierauf werden Nutzer notfalls im Rahmen der Registrierung
            hingewiesen) und nicht an anderer Stelle verwendet werden. Die
            Beiträge im Forum sind für die Öffentlichkeit sichtbar, es sei denn,
            deren Sichtbarkeit ist auf bestimmte Mitglieder oder
            Mitgliedergruppen beschränkt. Die Beiträge der Verfasser werden mit
            deren Namen, sofern registriert oder angegeben, dem Zeitpunkt und
            dem Eintragsinhalt gespeichert. Bei Anmeldungen und beim Verfassen
            von Einträgen werden ferner die IP-Adressen der Nutzer gespeichert,
            falls die Einträge einen unzulässigen Inhalt aufweisen sollten und
            die IP-Adressen der Rechtsverfolgung dienen könnten. Der
            Verantwortliche behält sich vor, die Anmeldungen und Einträge auf
            Grundlage einer sachgerechten Abwägung zu löschen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern),
              Inhaltsdaten (Texteingaben, Fotografien, Videos),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen),
              Nutzungsdaten (besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
              und Service, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von
              Anfragen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m451">Single-Sign-On-Anmeldung</h2>
          <p>
            Als „Single-Sign-On“ oder „Single-Sign-On-Anmeldung bzw.
            „-Authentifizierung“ werden Verfahren bezeichnet, die es Nutzern
            erlauben, sich mit Hilfe eines Nutzerkontos bei einem Anbieter von
            Single-Sign-On-Verfahren (z.B. einem sozialen Netzwerk), auch bei
            unserem Onlineangebot, anzumelden. Voraussetzung der
            Single-Sign-On-Authentifizierung ist, dass die Nutzer bei dem
            jeweiligen Single-Sign-On-Anbieter registriert sind und die
            erforderlichen Zugangsdaten in dem dafür vorgesehenen Onlineformular
            eingeben, bzw. schon bei dem Single-Sign-On-Anbieter angemeldet sind
            und die Single-Sign-On-Anmeldung via Schaltfläche bestätigen.
          </p>
          <p>
            Die Authentifizierung erfolgt direkt bei dem jeweiligen
            Single-Sign-On-Anbieter. Im Rahmen einer solchen Authentifizierung
            erhalten wir eine Nutzer-ID mit der Information, dass der Nutzer
            unter dieser Nutzer-ID beim jeweiligen Single-Sign-On-Anbieter
            eingeloggt ist und eine für uns für andere Zwecke nicht weiter
            nutzbare ID (sog „User Handle“). Ob uns zusätzliche Daten
            übermittelt werden, hängt allein von dem genutzten
            Single-Sign-On-Verfahren ab, von den gewählten Datenfreigaben im
            Rahmen der Authentifizierung und zudem davon, welche Daten Nutzer in
            den Privatsphäre- oder sonstigen Einstellungen des Nutzerkontos beim
            Single-Sign-On-Anbieter freigegeben haben. Es können je nach
            Single-Sign-On-Anbieter und der Wahl der Nutzer verschiedene Daten
            sein, in der Regel sind es die E-Mail-Adresse und der Benutzername.
            Das im Rahmen des Single-Sign-On-Verfahrens eingegebene Passwort bei
            dem Single-Sign-On-Anbieter ist für uns weder einsehbar, noch wird
            es von uns gespeichert.
          </p>
          <p>
            Die Nutzer werden gebeten, zu beachten, dass deren bei uns
            gespeicherte Angaben automatisch mit ihrem Nutzerkonto beim
            Single-Sign-On-Anbieter abgeglichen werden können, dies jedoch nicht
            immer möglich ist oder tatsächlich erfolgt. Ändern sich z.B. die
            E-Mail-Adressen der Nutzer, müssen sie diese manuell in ihrem
            Nutzerkonto bei uns ändern.
          </p>
          <p>
            Die Single-Sign-On-Anmeldung können wir, sofern mit den Nutzern
            vereinbart, im Rahmen der oder vor der Vertragserfüllung einsetzen,
            soweit die Nutzer darum gebeten wurden, im Rahmen einer Einwilligung
            verarbeiten und setzen sie ansonsten auf Grundlage der berechtigten
            Interessen unsererseits und der Interessen der Nutzer an einem
            effektiven und sicheren Anmeldesystem ein.
          </p>
          <p>
            Sollten Nutzer sich einmal entscheiden, die Verknüpfung ihres
            Nutzerkontos beim Single-Sign-On-Anbieter nicht mehr für das
            Single-Sign-On-Verfahren nutzen zu wollen, müssen sie diese
            Verbindung innerhalb ihres Nutzerkontos beim Single-Sign-On-Anbieter
            aufheben. Möchten Nutzer deren Daten bei uns löschen, müssen sie
            ihre Registrierung bei uns kündigen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
              und Service, Anmeldeverfahren.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Facebook Single-Sign-On:</strong>{' '}
              Authentifizierungsdienst; Dienstanbieter:{' '}
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com
              </a>
              , Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker
              Way, Menlo Park, CA 94025, USA; Website:{' '}
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://www.facebook.com/about/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/about/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out):{' '}
              <a
                href="https://www.facebook.com/settings?tab=ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/settings?tab=ads
              </a>
              .
            </li>
            <li>
              <strong>Google Single-Sign-On:</strong> Authentifizierungsdienst;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://www.google.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.de
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für die
              Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
            <li>
              <strong>Instagram Single-Sign-On:</strong>{' '}
              Authentifizierungsdienst; Dienstanbieter: Instagram Inc., 1601
              Willow Road, Menlo Park, CA, 94025, USA; Website:{' '}
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.instagram.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="http://instagram.com/about/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://instagram.com/about/legal/privacy
              </a>
              .
            </li>
          </ul>
          <h2 id="m104">Blogs und Publikationsmedien</h2>
          <p>
            Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation
            und Publikation (nachfolgend „Publikationsmedium“). Die Daten der
            Leser werden für die Zwecke des Publikationsmediums nur insoweit
            verarbeitet, als es für dessen Darstellung und die Kommunikation
            zwischen Autoren und Lesern oder aus Gründen der Sicherheit
            erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur
            Verarbeitung der Besucher unseres Publikationsmediums im Rahmen
            dieser Datenschutzhinweise.
          </p>
          <p>
            <strong>Kommentare und Beiträge</strong>: Wenn Nutzer Kommentare
            oder sonstige Beiträge hinterlassen, können ihre IP-Adressen auf
            Grundlage unserer berechtigten Interessen gespeichert werden. Das
            erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und
            Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen,
            verbotene politische Propaganda etc.). In diesem Fall können wir
            selbst für den Kommentar oder Beitrag belangt werden und sind daher
            an der Identität des Verfassers interessiert.
          </p>
          <p>
            Des Weiteren behalten wir uns vor, auf Grundlage unserer
            berechtigten Interessen die Angaben der Nutzer zwecks Spamerkennung
            zu verarbeiten.
          </p>
          <p>
            Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von
            Umfragen die IP-Adressen der Nutzer für deren Dauer zu speichern und
            Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden.
          </p>
          <p>
            Die im Rahmen der Kommentare und Beiträge mitgeteilten Informationen
            zur Person, etwaige Kontakt- sowie Webseiteninformationen als auch
            die inhaltlichen Angaben werden von uns bis zum Widerspruch der
            Nutzer dauerhaft gespeichert.
          </p>
          <p>
            <strong>Kommentarabonnements</strong>: Die Nachfolgekommentare
            können von Nutzern mit deren Einwilligung abonniert werden. Die
            Nutzer erhalten eine Bestätigungsemail, um zu überprüfen, ob sie der
            Inhaber der eingegebenen Emailadresse sind. Nutzer können laufende
            Kommentarabonnements jederzeit abbestellen. Die Bestätigungsemail
            wird Hinweise zu den Widerrufsmöglichkeiten enthalten. Für die
            Zwecke des Nachweises der Einwilligung der Nutzer speichern wir den
            Anmeldezeitpunkt nebst der IP-Adresse der Nutzer und löschen diese
            Informationen, wenn Nutzer sich von dem Abonnement abmelden.
          </p>
          <p>
            Sie können den Empfang unseres Abonnements jederzeit kündigen, d.h.,
            Ihre Einwilligungen widerrufen. Wir können die ausgetragenen
            E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
            berechtigten Interessen speichern, bevor wir sie löschen, um eine
            ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung
            dieser Daten wird auf den Zweck einer möglichen Abwehr von
            Ansprüchen beschränkt. Ein individueller Löschungsantrag ist
            jederzeit möglich, sofern zugleich das ehemalige Bestehen einer
            Einwilligung bestätigt wird.
          </p>
          <p>
            <strong>Abruf von WordPress-Emojis und -Smilies</strong>: Innerhalb
            unseres WordPress-Blogs werden zu Zwecken der effizienten Einbindung
            von Inhaltselementen grafische Emojis (bzw. Smilies), d.h., kleine
            grafische Dateien, die Gefühle ausdrücken, eingesetzt, bezogen von
            externen Servern. Die Anbieter der Server erheben die IP-Adressen
            der Nutzer. Dies ist notwendig, damit die Emoji-Dateien an die
            Browser der Nutzer übermittelt werden können.
          </p>
          <p>
            <strong>Akismet Anti-Spam-Prüfung</strong>: Wir nutzen auf Grundlage
            unserer berechtigten Interessen den Dienst „Akismet“. Mit Hilfe von
            Akismet werden Kommentare echter Menschen von Spam-Kommentaren
            unterschieden. Dazu werden alle Kommentarangaben an einen Server in
            den USA verschickt, wo sie analysiert und für Vergleichszwecke vier
            Tage lang gespeichert werden. Ist ein Kommentar als Spam eingestuft
            worden, werden die Daten über diese Zeit hinaus gespeichert. Zu
            diesen Angaben gehören der eingegebene Name, die E-Mailadresse, die
            IP-Adresse, der Kommentarinhalt, der Referrer, Angaben zum
            verwendeten Browser sowie zum Computersystem und die Zeit des
            Eintrags.
          </p>
          <p>
            Die Nutzer können gerne Pseudonyme nutzen oder auf die Eingabe des
            Namens oder der Emailadresse verzichten. Sie können die Übertragung
            der Daten komplett verhindern, indem sie unser Kommentarsystem nicht
            nutzen. Das wäre schade, aber leider sehen wir keine Alternativen,
            die ebenso effektiv funktionieren.
          </p>
          <p>
            <strong>Profilbilder von Gravatar</strong>: Wir setzen innerhalb
            unseres Onlineangebotes und insbesondere im Blog den Dienst Gravatar
            ein.
          </p>
          <p>
            Gravatar ist ein Dienst, bei dem sich Nutzer anmelden und
            Profilbilder und ihre E-Mail-Adressen hinterlegen können. Wenn
            Nutzer mit der jeweiligen E-Mail-Adresse auf anderen Onlinepräsenzen
            (vor allem in Blogs) Beiträge oder Kommentare hinterlassen, können
            deren Profilbilder neben den Beiträgen oder Kommentaren dargestellt
            werden. Hierzu wird die von den Nutzern mitgeteilte E-Mail-Adresse
            an Gravatar zwecks Prüfung, ob zu ihr ein Profil gespeichert ist,
            verschlüsselt übermittelt. Dies ist der einzige Zweck der
            Übermittlung der E-Mail-Adresse. Sie wird nicht für andere Zwecke
            verwendet, sondern danach gelöscht.
          </p>
          <p>
            Die Nutzung von Gravatar erfolgt auf Grundlage unserer berechtigten
            Interessen, da wir mit Hilfe von Gravatar den Beitrags- und
            Kommentarverfassern die Möglichkeit bieten, ihre Beiträge mit einem
            Profilbild zu personalisieren.
          </p>
          <p>
            Durch die Anzeige der Bilder bringt Gravatar die IP-Adresse der
            Nutzer in Erfahrung, da dies für eine Kommunikation zwischen einem
            Browser und einem Onlineservice notwendig ist.
          </p>
          <p>
            Wenn Nutzer nicht möchten, dass ein mit ihrer E-Mail-Adresse bei
            Gravatar verknüpftes Benutzerbild in den Kommentaren erscheint,
            sollten sie zum Kommentieren eine E-Mail-Adresse nutzen, welche
            nicht bei Gravatar hinterlegt ist. Wir weisen ferner darauf hin,
            dass es auch möglich ist eine anonyme oder gar keine E-Mail-Adresse
            zu verwenden, falls die Nutzer nicht wünschen, dass die eigene
            E-Mail-Adresse an Gravatar übersendet wird. Nutzer können die
            Übertragung der Daten komplett verhindern, indem sie unser
            Kommentarsystem nicht nutzen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern),
              Inhaltsdaten (Texteingaben, Fotografien, Videos), Nutzungsdaten
              (besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen),
              Vertragsdaten (Vertragsgegenstand, Laufzeit, Kundenkategorie).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen
              und Service, Feedback (Sammeln von Feedback, z.B. via Formular),
              Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen,
              Kontaktanfragen und Kommunikation, Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO),
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Schutz
              lebenswichtiger Interessen (Art. 6 Abs. 1 S. 1 lit. d. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Abruf von WordPress-Emojis und -Smilies:</strong> Abruf
              von WordPress-Emojis und -Smilies; Dienstanbieter: Automattic
              Inc., 60 29th Street #343, San Francisco, CA 94110, USA; Website:{' '}
              <a
                href="https://automattic.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://automattic.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com/privacy
              </a>
              .
            </li>
            <li>
              <strong>Akismet Anti-Spam-Prüfung:</strong> Akismet
              Anti-Spam-Prüfung; Dienstanbieter: Automattic Inc., 60 29th Street
              #343, San Francisco, CA 94110, USA; Website:{' '}
              <a
                href="https://automattic.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://automattic.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com/privacy
              </a>
              .
            </li>
            <li>
              <strong>Profilbilder von Gravatar:</strong> Profilbilder;
              Dienstanbieter: Automattic Inc., 60 29th Street #343, San
              Francisco, CA 94110, USA; Website:{' '}
              <a
                href="https://automattic.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://automattic.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://automattic.com/privacy
              </a>
              .
            </li>
          </ul>
          <h2 id="m182">Kontaktaufnahme</h2>
          <p>
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
            Telefon oder via soziale Medien) werden die Angaben der anfragenden
            Personen verarbeitet, soweit dies zur Beantwortung der
            Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
          </p>
          <p>
            Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen
            oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
            vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
            Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
            der Beantwortung der Anfragen.
          </p>
          <p>
            <strong>Chat-Funktion</strong>: Zu Zwecken der Kommunikation und der
            Beantwortung von Anfragen bieten wir innerhalb unseres
            Onlineangebotes eine Chat-Funktion an. Die Eingaben der Nutzer
            innerhalb des Chats werden für Zwecke der Beantwortung ihrer
            Anfragen verarbeitet.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern),
              Inhaltsdaten (Texteingaben, Fotografien, Videos), Nutzungsdaten
              (besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner,
              Interessenten.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation, Verwaltung und Beantwortung von Anfragen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Google-reCAPTCHA </strong>
          </p>
          <p>
            Wir nutzen “Google reCAPTCHA” bzw. “Google Invisible reCAPTCHA” (im
            Folgenden “reCAPTCHA”) auf unserer Website. Anbieter ist die Google
            Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
            (“Google”). <br />
            Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren
            Websites (z.B. in einem Kontaktformular) durch einen Menschen oder
            durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
            Merkmale. Diese Analyse beginnt automatisch, sobald der
            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
            verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des
            Websitebesuchers auf der Website oder vom Nutzer getätigte
            Mausbewegungen). Die bei der Analyse erfassten Daten werden an
            Google weitergeleitet.
          </p>
          <p>
            Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
            Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
            stattfindet.
          </p>
          <p>
            Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
            DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran,
            seine Webangebote vor missbräuchlicher automatisierter Ausspähung
            und vor SPAM zu schützen. Weitere Informationen zu Google reCAPTCHA
            sowie die Datenschutzerklärung von Google entnehmen Sie folgenden
            Links:{' '}
            <a
              href="https://www.google.com/intl/de/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/intl/de/policies/privacy/
            </a>{' '}
            und{' '}
            <a
              href="https://www.google.com/recaptcha/intro/android.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/recaptcha/intro/android.html
            </a>{' '}
            .
          </p>
          <h2 id="m391">Kommunikation via Messenger</h2>
          <p>
            Wir setzen zu Zwecken der Kommunikation Messenger-Dienste ein und
            bitten daher darum, die nachfolgenden Hinweise zur
            Funktionsfähigkeit der Messenger, zur Verschlüsselung, zur Nutzung
            der Metadaten der Kommunikation und zu Ihren
            Widerspruchsmöglichkeiten zu beachten.
          </p>
          <p>
            Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder
            E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
            Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes
            angegebenen Kontaktmöglichkeiten.
          </p>
          <p>
            Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der
            Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
            Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte
            Bilder) von Ende zu Ende verschlüsselt werden. Das bedeutet, dass
            der Inhalt der Nachrichten nicht einsehbar ist, nicht einmal durch
            die Messenger-Anbieter selbst. Sie sollten immer eine aktuelle
            Version der Messenger mit aktivierter Verschlüsselung nutzen, damit
            die Verschlüsselung der Nachrichteninhalte sichergestellt ist.
          </p>
          <p>
            Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf
            hin, dass die Anbieter der Messenger zwar nicht den Inhalt einsehen,
            aber in Erfahrung bringen können, dass und wann
            Kommunikationspartner mit uns kommunizieren sowie technische
            Informationen zum verwendeten Gerät der Kommunikationspartner und je
            nach Einstellungen ihres Gerätes auch Standortinformationen
            (sogenannte Metadaten) verarbeitet werden.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen: </strong>Sofern wir
            Kommunikationspartner vor der Kommunikation mit ihnen via Messenger
            um eine Erlaubnis bitten, ist die Rechtsgrundlage unserer
            Verarbeitung ihrer Daten deren Einwilligung. Im Übrigen, falls wir
            nicht um eine Einwilligung bitten und sie z.B. von sich aus Kontakt
            mit uns aufnehmen, nutzen wir Messenger im Verhältnis zu unseren
            Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine
            vertragliche Maßnahme und im Fall anderer Interessenten und
            Kommunikationspartner auf Grundlage unserer berechtigten Interessen
            an einer schnellen und effizienten Kommunikation und Erfüllung der
            Bedürfnisse unser Kommunikationspartner an der Kommunikation via
            Messengern. Ferner weisen wir Sie darauf hin, dass wir die uns
            mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an
            die Messenger übermitteln.
          </p>
          <p>
            <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können
            jederzeit eine erteilte Einwilligung widerrufen und der
            Kommunikation mit uns via Messenger jederzeit widersprechen. Im Fall
            der Kommunikation via Messenger löschen wir die Nachrichten
            entsprechend unseren generellen Löschrichtlinien (d.h. z.B., wie
            oben beschrieben, nach Ende vertraglicher Beziehungen, im Kontext
            von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
            können, etwaige Auskünfte der Kommunikationspartner beantwortet zu
            haben, wenn kein Rückbezug auf eine vorhergehende Konversation zu
            erwarten ist und der Löschung keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen.
          </p>
          <p>
            <strong>
              Vorbehalt des Verweises auf andere Kommunikationswege:
            </strong>{' '}
            Zum Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen
            Ihrer Sicherheit vorbehalten, Anfragen über Messenger nicht zu
            beantworten. Das ist der Fall, wenn z.B. Vertragsinterna besonderer
            Geheimhaltung bedürfen oder eine Antwort über den Messenger den
            formellen Ansprüchen nicht genügt. In solchen Fällen verweisen wir
            Sie auf adäquatere Kommunikationswege.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (E-Mail,
              Telefonnummern), Nutzungsdaten (besuchte Webseiten, Interesse an
              Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation, Direktmarketing (per E-Mail oder postalisch).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Facebook-Messenger:</strong> Facebook-Messenger mit
              Ende-zu-Ende-Verschlüsselung (die Ende-zu-Ende-Verschlüsselung des
              Facebook-Messengers setzt eine Aktivierung voraus, sofern sie
              nicht standardmäßig aktiviert sein sollte); Dienstanbieter:{' '}
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com
              </a>
              , Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker
              Way, Menlo Park, CA 94025, USA; Website:{' '}
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://www.facebook.com/about/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/about/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out):{' '}
              <a
                href="https://www.facebook.com/settings?tab=ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/settings?tab=ads
              </a>
              .
            </li>
          </ul>
          <h2 id="m408">Umfragen und Befragungen</h2>
          <p>
            Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
            „Befragungen“) werden anonym ausgewertet. Eine Verarbeitung
            personenbezogener Daten erfolgt nur insoweit, als dies zu
            Bereitstellung und technischen Durchführung der Umfragen
            erforderlich ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage
            im Browser des Nutzers darzustellen oder mithilfe eines temporären
            Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu
            ermöglichen) oder Nutzer eingewilligt haben.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die
            Teilnehmer um eine Einwilligung in die Verarbeitung iherer Daten
            bitten, ist diese Rechtsgrundlage der Verarbeitung, ansonsten
            erfolgt die Verarbeitung der Daten der Teilnehmer auf Grundlage
            unserer berechtigten Interessen an der Durchführung einer objektiven
            Umfrage.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (E-Mail,
              Telefonnummern), Inhaltsdaten (Texteingaben, Fotografien, Videos),
              Nutzungsdaten (besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten), Meta-/Kommunikationsdaten (Geräte-Informationen,
              IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner,
              Nutzer (Websitebesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation, Direktmarketing (per E-Mail oder postalisch),
              Tracking (Interessens-/verhaltensbezogenes Profiling, Cookies),
              Feedback (Sammeln von Feedback, z.B. via Formular).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Google-Formular:</strong> Google-Cloud-Formulare;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://firebase.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://firebase.google.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
          </ul>
          <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
          <p>
            Um unser Onlineangebot sicher und effizient bereitstellen zu können,
            nehmen wir die Leistungen von einem oder mehreren
            Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen
            verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu
            diesen Zwecken können wir Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste sowie Sicherheitsleistungen und technische
            Wartungsleistungen in Anspruch nehmen.
          </p>
          <p>
            Zu den im Rahmen der Bereitstellung des Hostingangebotes
            verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes
            betreffenden Angaben gehören, die im Rahmen der Nutzung und der
            Kommunikation anfallen. Hierzu gehören regelmäßig die IP-Adresse,
            die notwendig ist, um die Inhalte von Onlineangeboten an Browser
            ausliefern zu können, und alle innerhalb unseres Onlineangebotes
            oder von Webseiten getätigten Eingaben.
          </p>
          <p>
            <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in
            Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den
            Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen
            Zwecken werden die Adressen der Empfänger sowie Absender als auch
            weitere Informationen betreffend den E-Mailversand (z.B. die
            beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails
            verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
            Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu
            beachten, dass E-Mails im Internet grundsätzlich nicht verschlüsselt
            versendet werden. Im Regelfall werden E-Mails zwar auf dem
            Transportweg verschlüsselt, aber (sofern kein sogenanntes
            Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf
            den Servern, von denen sie abgesendet und empfangen werden. Wir
            können daher für den Übertragungsweg der E-Mails zwischen dem
            Absender und dem Empfang auf unserem Server keine Verantwortung
            übernehmen.
          </p>
          <p>
            <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst
            (bzw. unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf
            den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können
            die Adresse und Name der abgerufenen Webseiten und Dateien, Datum
            und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
            erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
            des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
            Regelfall IP-Adressen und der anfragende Provider gehören.
          </p>
          <p>
            Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
            eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken) und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten
              (Texteingaben, Fotografien, Videos), Nutzungsdaten (besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p>Mittwald Hosting</p>
          <p>Ab dem 25.05.2018 gelten bei Mittwald folgende Angaben:</p>
          <p>
            Die Access-Logs der Webserver protokollieren, welche Seitenaufrufe
            zu welchem Zeitpunkt stattgefunden haben. Sie beinhalten folgende
            Daten: IP, Verzeichnisschutzbenutzer, Datum, Uhrzeit, aufgerufene
            Seiten, Protokolle, Statuscode, Datenmenge, Referer, User Agent,
            aufgerufener Hostname.
          </p>
          <p>
            Die IP-Adressen werden dabei anonymisiert gespeichert. Hierzu werden
            die letzten drei Ziffern entfernt, d.h. aus 127.0.0.1 wird
            127.0.0.*. IPv6-Adressen werden ebenfalls anonymisiert. Die
            anonymisierten IP-Adressen werden für 60 Tage aufbewahrt. Angaben
            zum verwendeten Verzeichnisschutzbenutzer werden nach einem Tag
            anonymisiert.
          </p>
          <p>
            Error-Logs, welche fehlerhafte Seitenaufrufe protokollieren, werden
            nach sieben Tagen gelöscht. Diese beinhalten neben den
            Fehlermeldungen die zugreifende IP-Adresse und je nach Fehler die
            aufgerufene Webseite.
          </p>
          <p>
            Zugriffe über FTP werden mit anonymisierter Angabe zu Benutzername
            und IP-Adresse protokolliert und für 60 Tage aufbewahrt.
          </p>
          <p>
            Die Mail-Logs für den Versand von E-Mails aus der Webumgebung heraus
            werden nach einem Tag anonymisiert und anschließend für 60 Tage
            vorgehalten. Bei der Anonymisierung werden alle Daten zum
            Absender/Empfänger etc. entfernt. Es bleiben lediglich die Daten zum
            Versandzeitpunkt sowie die Information, wie die E-Mail verarbeitet
            wurde, erhalten (Queue-ID oder nicht gesendet).
          </p>
          <p>
            Mail-Logs für den Versand über unsere Mailserver werden nach vier
            Wochen gelöscht. Die längere Vorhaltezeit ist für die Sicherstellung
            der Funktionalität der Mail-Services und Spambekämpfung notwendig.
          </p>
          <p>
            Eine individuelle Festlegung der Speicherdauer ist nicht möglich.
          </p>
          <h2 id="m17">Newsletter und Breitenkommunikation</h2>
          <p>
            Wir versenden Newsletter, E-Mails und weitere elektronische
            Benachrichtigungen (nachfolgend „Newsletter“) nur mit der
            Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern
            im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret
            umschrieben werden, sind sie für die Einwilligung der Nutzer
            maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu
            unseren Leistungen und uns.
          </p>
          <p>
            Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich
            aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch
            bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter,
            oder weitere Angaben, sofern diese für die Zwecke des Newsletters
            erforderlich sind, zu tätigen.
          </p>
          <p>
            <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem
            Newsletter erfolgt grundsätzlich in einem sogenannte
            Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine
            E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten
            werden. Diese Bestätigung ist notwendig, damit sich niemand mit
            fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum
            Newsletter werden protokolliert, um den Anmeldeprozess entsprechend
            den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört
            die Speicherung des Anmelde- und des Bestätigungszeitpunkts als auch
            der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
            Versanddienstleister gespeicherten Daten protokolliert.
          </p>
          <p>
            <strong>Löschung und Einschränkung der Verarbeitung: </strong>Wir
            können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
            Grundlage unserer berechtigten Interessen speichern, bevor wir sie
            löschen, um eine ehemals gegebene Einwilligung nachweisen zu können.
            Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
            Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag
            ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer
            Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften
            Beachtung von Widersprüchen behalten wir uns die Speicherung der
            E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste
            (sogenannte „Blacklist“) vor.
          </p>
          <p>
            Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
            unserer berechtigten Interessen zu Zwecken des Nachweises seines
            ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem
            Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer
            berechtigten Interessen an einem effizienten und sicheren
            Versandsystem.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der
            Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger
            oder, falls eine Einwilligung nicht erforderlich ist, auf Grundlage
            unserer berechtigten Interessen am Direktmarketing, sofern und
            soweit diese gesetzlich, z.B. im Fall von Bestandskundenwerbung,
            erlaubt ist. Soweit wir einen Dienstleister mit dem Versand von
            E-Mails beauftragen, geschieht dies auf der Grundlage unserer
            berechtigten Interessen. Das Registrierungsverfahren wird auf der
            Grundlage unserer berechtigten Interessen aufgezeichnet, um
            nachzuweisen, dass es in Übereinstimmung mit dem Gesetz durchgeführt
            wurde.
          </p>
          <p>
            <strong>Inhalte</strong>:
          </p>
          <p>
            Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.
          </p>
          <p>
            <strong>Erfolgsmessung</strong>: Die Newsletter enthalten einen
            sogenannte „web-beacon“, d.h., eine pixelgroße Datei, die beim
            Öffnen des Newsletters von unserem Server, bzw., sofern wir einen
            Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im
            Rahmen dieses Abrufs werden zunächst technische Informationen, wie
            Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse
            und der Zeitpunkt des Abrufs, erhoben.
          </p>
          <p>
            Diese Informationen werden zur technischen Verbesserung unseres
            Newsletters anhand der technischen Daten oder der Zielgruppen und
            ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der
            IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese
            Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter
            geöffnet werden, wann sie geöffnet werden und welche Links geklickt
            werden. Diese Informationen können aus technischen Gründen zwar den
            einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch
            weder unser Bestreben noch, sofern eingesetzt, das des
            Versanddienstleisters, einzelne Nutzer zu beobachten. Die
            Auswertungen dienen uns vielmehr dazu, die Lesegewohnheiten unserer
            Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder
            unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer
            zu versenden.
          </p>
          <p>
            Die Auswertung des Newsletters und die Erfolgsmessung erfolgen,
            vorbehaltlich einer ausdrücklichen Einwilligung der Nutzer, auf
            Grundlage unserer berechtigten Interessen zu Zwecken des Einsatzes
            eines nutzerfreundlichen sowie sicheren Newslettersystems, welches
            sowohl unseren geschäftlichen Interessen dient, als auch den
            Erwartungen der Nutzer entspricht.
          </p>
          <p>
            Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich,
            in diesem Fall muss das gesamte Newsletterabonnement gekündigt, bzw.
            muss ihm widersprochen werden.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen),
              Nutzungsdaten (besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (per
              E-Mail oder postalisch).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
            <li>
              <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den
              Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
              Einwilligungen widerrufen, bzw. dem weiteren Empfang
              widersprechen. Einen Link zur Kündigung des Newsletters finden Sie
              entweder am Ende eines jeden Newsletters oder können sonst eine
              der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail,
              hierzu nutzen.
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Mailchimp:</strong> E-Mail-Marketing-Plattform;
              Dienstanbieter: \“Mailchimp\“ – Rocket Science Group, LLC, 675
              Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA; Website:{' '}
              <a
                href="https://mailchimp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://mailchimp.com/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/legal/privacy/
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active
              </a>
              .
            </li>
          </ul>
          <p>
            Der Versand der Newsletter erfolgt mittels „MailChimp“, einer
            Newsletterversandplattform des US-Anbieters Rocket Science Group,
            LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
          </p>
          <p>
            Die E-Mail-Adressen unserer Newsletterempfänger, als auch deren
            weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden auf
            den Servern von MailChimp in den USA gespeichert. MailChimp
            verwendet diese Informationen zum Versand und zur Auswertung der
            Newsletter in unserem Auftrag. Des Weiteren kann MailChimp nach
            eigenen Informationen diese Daten zur Optimierung oder Verbesserung
            der eigenen Services nutzen, z.B. zur technischen Optimierung des
            Versandes und der Darstellung der Newsletter oder für
            wirtschaftliche Zwecke, um zu bestimmen aus welchen Ländern die
            Empfänger kommen. MailChimp nutzt die Daten unserer
            Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder
            an Dritte weiterzugeben.
          </p>
          <p>
            Die Datenschutzbestimmungen von MailChimp finden Sie hier:
            <a
              href="https://mailchimp.com/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://mailchimp.com/legal/privacy
            </a>
          </p>
          <p>Statistische Erhebung und Analysen</p>
          <p>
            Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine
            pixelgroße Datei, die beim Öffnen des Newsletters von dem Server von
            MailChimp abgerufen wird. Im Rahmen dieses Abrufs werden zunächst
            technische Informationen, wie Informationen zum Browser und Ihrem
            System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben.
            Diese Informationen werden zur technischen Verbesserung der Services
            anhand der technischen Daten oder der Zielgruppen und ihres
            Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse
            bestimmbar sind) oder der Zugriffszeiten genutzt.
          </p>
          <p>
            Zu den statistischen Erhebungen gehört ebenfalls die Feststellung,
            ob die Newsletter geöffnet werden, wann sie geöffnet werden und
            welche Links geklickt werden. Diese Informationen können aus
            technischen Gründen zwar den einzelnen Newsletterempfängern
            zugeordnet werden. Es ist jedoch weder unser Bestreben, noch das von
            MailChimp, einzelne Nutzer zu beobachten. Die Auswertungen dienen
            uns viel mehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen
            und unsere Inhalte auf sie anzupassen oder unterschiedliche Inhalte
            entsprechend den Interessen unserer Nutzer zu versenden.
          </p>
          <h2 id="m237">Gewinnspiele und Wettbewerbe</h2>
          <p>
            Wir verarbeiten personenbezogene Daten der Teilnehmer von
            Gewinnspielen und Wettbewerben nur unter Einhaltung der
            einschlägigen Datenschutzbestimmungen, soweit die Verarbeitung zur
            Bereitstellung, Durchführung und Abwicklung des Gewinnspiels
            vertraglich erforderlich ist, die Teilnehmer in die Verarbeitung
            eingewilligt haben oder die Verarbeitung unseren berechtigten
            Interessen dient (z.B. an der Sicherheit des Gewinnspiels oder dem
            Schutz unserer Interessen vor Missbrauch durch mögliche Erfassung
            von IP-Adressen bei Einreichung von Gewinnspielbeiträgen).
          </p>
          <p>
            Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer
            veröffentlicht werden (z.B. im Rahmen einer Abstimmung oder
            Präsentation der Gewinnspielbeiträge bzw. der Gewinner oder der
            Berichterstattung zum Gewinnspiel), weisen wir darauf hin, dass die
            Namen der Teilnehmer in diesem Zusammenhang ebenfalls veröffentlicht
            werden können. Die Teilnehmer können dem jederzeit widersprechen.
          </p>
          <p>
            Findet das Gewinnspiel innerhalb einer Online-Plattform oder eines
            sozialen Netzwerks (z.B. Facebook oder Instagram, nachfolgend
            bezeichnet als „Online-Plattform“) statt, gelten zusätzlich die
            Nutzungs- und Datenschutzbestimmungen der jeweiligen Plattformen. In
            diesen Fällen weisen wir darauf hin, dass wir für die im Rahmen des
            Gewinnspiels mitgeteilten Angaben der Teilnehmer verantwortlich sind
            und Anfragen im Hinblick auf das Gewinnspiel an uns zu richten sind.
          </p>
          <p>
            Die Daten der Teilnehmer werden gelöscht, sobald das Gewinnspiel
            oder der Wettbewerb beendet sind und die Daten nicht mehr
            erforderlich sind, um die Gewinner zu informieren oder weil mit
            Rückfragen zum Gewinnspiel zu rechnen ist. Grundsätzlich werden die
            Daten der Teilnehmer spätestens 6 Monate nach Ende des Gewinnspiels
            gelöscht. Daten der Gewinner können länger einbehalten werden, um
            z.B. Rückfragen zu den Gewinnen beantworten oder die
            Gewinnleistungen erfüllen zu können; in diesem Fall richtet sich die
            Aufbewahrungsdauer nach der Art des Gewinns und beträgt z.B. bei
            Sachen oder Leistungen bis zu drei Jahre, um z.B.
            Gewährleistungsfälle bearbeiten zu können. Ferner können die Daten
            der Teilnehmer länger gespeichert werden, z.B. in Form der
            Berichterstattung zum Gewinnspiel in Online- und Offline-Medien.
          </p>
          <p>
            Sofern Daten im Rahmen des Gewinnspiels auch zu anderen Zwecken
            erhoben wurden, richten sich deren Verarbeitung und die
            Aufbewahrungsdauer nach den Datenschutzhinweisen zu dieser Nutzung
            (z.B. im Fall einer Anmeldung zum Newsletter im Rahmen eines
            Gewinnspiels).
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Inhaltsdaten (Texteingaben, Fotografien, Videos).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Teilnehmer von Gewinnspielen
              und Wettbewerben.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Durchführung von
              Gewinnspielen und Wettbewerben (Conducting sweepstakes and
              contests).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
            </li>
          </ul>
          <h2 id="m263">Webanalyse und Optimierung</h2>
          <p>
            Die Webanalyse (auch als „Reichweitenmessung“ bezeichnet) dient der
            Auswertung der Besucherströme unseres Onlineangebotes und kann
            Verhalten, Interessen oder demographische Informationen zu den
            Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
            Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
            erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen
            oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
            einladen. Ebenso können wir nachvollziehen, welche Bereiche der
            Optimierung bedürfen.
          </p>
          <p>
            Neben der Webanalyse können wir auch Testverfahren einsetzen, um
            z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner
            Bestandteile zu testen und optimieren.
          </p>
          <p>
            Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in
            einer Datei (sogenannte „Cookie“) gespeichert oder ähnliche
            Verfahren mit dem gleichen Zweck genutzt werden. Zu diesen Angaben
            können z.B. betrachtete Inhalte, besuchte Webseiten und dort
            genutzte Elemente und technische Angaben, wie der verwendete
            Browser, das verwendete Computersystem sowie Angaben zu
            Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
            Standortdaten eingewilligt haben, können je nach Anbieter auch diese
            verarbeitet werden.
          </p>
          <p>
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
            nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
            Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
            im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
            Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
            gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
            eingesetzten Software kennen nicht die tatsächliche Identität der
            Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren
            Profilen gespeicherten Angaben.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
              (Zugriffsstatistiken, Erkennung wiederkehrender Besucher),
              Tracking (Interessens-/verhaltensbezogenes Profiling, Cookies),
              Besuchsaktionsauswertung, Profiling (Erstellen von Profilen der
              Nutzer).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
          </ul>
          <h2 id="m264">Onlinemarketing</h2>
          <p>
            Wir verarbeiten personenbezogene Daten zu Zwecken des
            Onlinemarketings, worunter insbesondere die Darstellung von
            werbenden und sonstigen Inhalten (zusammenfassend als „Inhalte“
            bezeichnet) anhand potentieller Interessen der Nutzer sowie die
            Messung ihrer Effektivität fallen.
          </p>
          <p>
            Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
            einer Datei (sogenannte „Cookie“) gespeichert oder ähnliche
            Verfahren genutzt, mittels derer die für die Darstellung der
            vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
            werden. Zu diesen Angaben können z.B. betrachtete Inhalte, besuchte
            Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner
            und technische Angaben, wie der verwendete Browser, das verwendete
            Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern
            Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben,
            können auch diese verarbeitet werden.
          </p>
          <p>
            Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
            nutzen wir IP-Masking-Verfahren (d.h., Pseudonymisierung durch
            Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im
            Rahmen des Onlinemarketingverfahren keine Klardaten der Nutzer (wie
            z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme.
            D.h., wir als auch die Anbieter der Onlinemarketingverfahren kennen
            nicht die tatsächlich Identität der Nutzer, sondern nur die in deren
            Profilen gespeicherten Angaben.
          </p>
          <p>
            Die Angaben in den Profilen werden im Regelfall in den Cookies oder
            mittels ähnlicher Verfahren gespeichert. Diese Cookies können später
            generell auch auf anderen Webseiten die dasselbe
            Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
            Darstellung von Inhalten analysiert als auch mit weiteren Daten
            ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
            gespeichert werden.
          </p>
          <p>
            Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das
            ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
            Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und
            das Netzwerk die Profile der Nutzer im den vorgenannten Angaben
            verbindet. Wir bitten darum, zu beachten, dass Nutzer mit den
            Anbietern zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der
            Registrierung, treffen können.
          </p>
          <p>
            Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
            Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können
            wir im Rahmen sogenannter Konversionsmessungen prüfen, welche
            unserer Onlinemarketingverfahren zu einer sogenannten Konversion
            geführt haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
            Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
            Marketingmaßnahmen verwendet.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen),
              Standortdaten, Sozialdaten (Daten, die dem Sozialgeheimnis (§ 35
              SGB I) unterliegen und z.B. von Sozialversicherungsträgern,
              Sozialhilfeträger oder Versorgungsbehörden verarbeitet werden.).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten), Interessenten, Kunden, Beschäftigte
              (Angestellte, Bewerber, Ehemalige Mitarbeiter),
              Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Tracking
              (Interessens-/verhaltensbezogenes Profiling, Cookies),
              Remarketing, Besuchsaktionsauswertung, Interessenbasiertes und
              verhaltensbezogenes Marketing, Profiling (Erstellen von Profilen
              der Nutzer), Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen), Reichweitenmessung (Zugriffsstatistiken,
              Erkennung wiederkehrender Besucher).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </li>
            <li>
              <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Wir verweisen
              auf die Datenschutzhinweise der jeweiligen Anbieter und die zu den
              Anbietern angegebenen Widerspruchsmöglichkeiten (sog.
              \“Opt-Out\“). Sofern keine explizite Opt-Out-Möglichkeit angegeben
              wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den
              Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
              Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
              empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten,
              die zusammenfassend auf jeweilige Gebiete gerichtet angeboten
              werden:a) Europa:{' '}
              <a
                href="https://www.youronlinechoices.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.youronlinechoices.eu
              </a>
              .<br />
              b) Kanada:{' '}
              <a
                href="https://www.youradchoices.ca/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.youradchoices.ca/choices
              </a>
              .<br />
              c) USA:{' '}
              <a
                href="https://www.aboutads.info/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.aboutads.info/choices
              </a>
              .<br />
              d) Gebietsübergreifend:{' '}
              <a
                href="http://optout.aboutads.info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://optout.aboutads.info
              </a>
              .
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Google Analytics:</strong> Onlinemarketing und Webanalyse;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://marketingplatform.google.com/intl/de/about/analytics/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://marketingplatform.google.com/intl/de/about/analytics/
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
          </ul>
          <h2 id="m135">Affiliate-Programme und Affiliate-Links</h2>
          <p>
            In unser Onlineangebot binden wir sogenannte Affiliate-Links oder
            andere Verweise (zu denen z.B. Rabatt-Codes gehören können) auf die
            Angebote und Leistungen von Drittanbietern ein (zusammenfassend
            bezeichnet als „Affiliate-Links“). Wenn Nutzer den Affiliate-Links
            folgen, bzw. anschließend die Angebote wahrnehmen, können wir von
            diesen Drittanbietern eine Provision oder sonstige Vorteile erhalten
            (zusammenfassend bezeichnet als „Provision“).
          </p>
          <p>
            Um nachverfolgen zu können, ob die Nutzer die Angebote eines von uns
            eingesetzten Affiliate-Links wahrgenommen haben, ist es notwendig,
            dass die jeweiligen Drittanbieter erfahren, dass die Nutzer einem
            innerhalb unseres Onlineangebotes eingesetzten Affiliate-Link
            gefolgt sind. Die Zuordnung der Affiliate-Links zu den jeweiligen
            Geschäftsabschlüssen oder zu sonstigen Aktionen (z.B. Käufen) dient
            alleine dem Zweck der Provisionsabrechnung und wird aufgehoben,
            sobald sie für den Zweck nicht mehr erforderlich ist.
          </p>
          <p>
            Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links können
            die Affiliate-Links um bestimmte Werte ergänzt werden, die ein
            Bestandteil des Links sind oder anderweitig, z.B. in einem Cookie,
            gespeichert werden können. Zu den Werten können insbesondere die
            Ausgangswebseite (Referrer), der Zeitpunkt, eine Online-Kennung der
            Betreiber der Webseite, auf der sich der Affiliate-Link befand, eine
            Online-Kennung des jeweiligen Angebotes, die Art des verwendeten
            Links, die Art des Angebotes und eine Online-Kennung des Nutzers
            gehören.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ferner kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen
            Leistungen sein, sofern der Einsatz der Drittanbieter in diesem
            Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf
            Grundlage unserer berechtigten Interessen (d.h. Interesse an
            effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
            verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
            Informationen zur Verwendung von Cookies in dieser
            Datenschutzerklärung hinweisen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Vertragsdaten
              (Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten
              (besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong>{' '}
              Affiliate-Nachverfolgung.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a DSGVO), Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <h2 id="m136">Präsenzen in sozialen Netzwerken</h2>
          <p>
            Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke, um mit
            den dort aktiven Nutzern zu kommunizieren oder um dort Informationen
            über uns anzubieten.
          </p>
          <p>
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
            Raumes der Europäischen Union verarbeitet werden können. Hierdurch
            können sich für die Nutzer Risiken ergeben, weil so z.B. die
            Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im
            Hinblick auf US-Anbieter, die unter dem Privacy-Shield zertifiziert
            sind oder vergleichbare Garantien eines sicheren Datenschutzniveaus
            bieten, weisen wir darauf hin, dass sie sich damit verpflichten, die
            Datenschutzstandards der EU einzuhalten.
          </p>
          <p>
            Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
            Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
            können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
            Interessen der Nutzer Nutzungsprofile erstellt werden. Die
            Nutzungsprofile können wiederum verwendet werden, um z.B.
            Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
            mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
            werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
            in denen das Nutzungsverhalten und die Interessen der Nutzer
            gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
            unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
            (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
            sind und bei diesen eingeloggt sind).
          </p>
          <p>
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
            und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
            Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
            Netzwerke.
          </p>
          <p>
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von
            Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
            bei den Anbietern geltend gemacht werden können. Nur die Anbieter
            haben jeweils Zugriff auf die Daten der Nutzer und können direkt
            entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
            dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (Namen,
              Adressen, etc.), Kontaktdaten (E-Mail, Telefonnummern),
              Inhaltsdaten (Texteingaben, Fotografien, Videos), Nutzungsdaten
              (besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
              Kommunikation, Tracking (Interessens-/verhaltensbezogenes
              Profiling, Cookies), Remarketing, Reichweitenmessung
              (Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>Instagram :</strong> Soziales Netzwerk; Dienstanbieter:
              Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA;
              Website:{' '}
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.instagram.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="http://instagram.com/about/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://instagram.com/about/legal/privacy
              </a>
              .
            </li>
            <li>
              <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter:
              Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
              Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo
              Park, CA 94025, USA; Website:{' '}
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://www.facebook.com/about/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/about/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für
              Werbeanzeigen:{' '}
              <a
                href="https://www.facebook.com/settings?tab=ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/settings?tab=ads
              </a>
              ; Zusätzliche Hinweise zum Datenschutz: Vereinbarung über
              gemeinsame Verarbeitung personenbezogener Daten auf
              Facebook-Seiten:{' '}
              <a
                href="https://www.facebook.com/legal/terms/page_controller_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/terms/page_controller_addendum
              </a>
              , Datenschutzhinweise für Facebook-Seiten:{' '}
              <a
                href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/terms/information_about_page_insights_data
              </a>
              .
            </li>
            <li>
              <strong>YouTube:</strong> Soziales Netzwerk; Dienstanbieter:
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out):{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
          </ul>
          <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
          <p>
            Wir binden in unser Onlineangebot Funktions- und Inhaltselemente
            ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
            bezeichnet als „Drittanbieter”) bezogen werden. Dabei kann es sich
            zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen
            sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als
            „Inhalte”).
          </p>
          <p>
            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
            Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
            IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
            IP-Adresse ist damit für die Darstellung dieser Inhalte oder
            Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
            verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
            Auslieferung der Inhalte verwenden. Drittanbieter können ferner
            sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“
            bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
            die „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf
            den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
            Informationen können ferner in Cookies auf dem Gerät der Nutzer
            gespeichert werden und unter anderem technische Informationen zum
            Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
            Besuchszeit sowie weitere Angaben zur Nutzung unseres
            Onlineangebotes enthalten als auch mit solchen Informationen aus
            anderen Quellen verbunden werden.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten, ist
            die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul>
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (Geräte-Informationen, IP-Adressen),
              Bestandsdaten (Namen, Adressen, etc.), Kontaktdaten (E-Mail,
              Telefonnummern), Inhaltsdaten (Texteingaben, Fotografien, Videos).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (Websitebesucher,
              Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit, Vertragliche Leistungen
              und Service, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von
              Anfragen.
            </li>
            <li>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit.
              a DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6
              Abs. 1 S. 1 lit. b. DSGVO).
            </li>
          </ul>
          <p>
            <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
          </p>
          <ul>
            <li>
              <strong>
                Google Fonts
                <br />
              </strong>
              Wir binden die Schriftarten (\“Google Fonts\“) des Anbieters
              Google ein, wobei die Daten der Nutzer allein zu Zwecken der
              Darstellung der Schriftarten im Browser der Nutzer verwendet
              werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten
              Interessen an einer technisch sicheren, wartungsfreien und
              effizienten Nutzung von Schriftarten, deren einheitlicher
              Darstellung sowie unter Berücksichtigung möglicher
              lizenzrechtlicher Restriktionen für deren Einbindung.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://fonts.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://fonts.google.com/
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active
              </a>
              .
            </li>
            <li>
              <strong>
                Google Maps
                <br />
              </strong>
              Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
              Google ein. Zu den verarbeiteten Daten können insbesondere
              IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht
              ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen
              ihrer Mobilgeräte vollzogen), erhoben werden. Dienstanbieter:
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://maps.google.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://maps.google.de
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
            <li>
              <strong>
                OpenStreetMap
                <br />
              </strong>
              Wir binden die Landkarten des Dienstes \“OpenStreetMap\“ ein, die
              auf Grundlage der Open Data Commons Open Database Lizenz (ODbL)
              durch die OpenStreetMap Foundation (OSMF) angeboten werden. Die
              Daten der Nutzer werden durch OpenStreetMap ausschließlich zu
              Zwecken der Darstellung der Kartenfunktionen und zur
              Zwischenspeicherung der gewählten Einstellungen verwendet. Zu
              diesen Daten können insbesondere IP-Adressen und Standortdaten der
              Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im
              Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen)
              erhoben werden. Dienstanbieter: OpenStreetMap Foundation (OSMF);
              Website:{' '}
              <a
                href="https://www.openstreetmap.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.openstreetmap.de
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://wiki.openstreetmap.org/wiki/Privacy_Policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://wiki.openstreetmap.org/wiki/Privacy_Policy
              </a>
              .
            </li>
            <li>
              <strong>
                ReCaptcha
                <br />
              </strong>
              Wir binden die Funktion \“ReCaptcha\“ zur Erkennung von Bots, z.B.
              bei Eingaben in Onlineformularen, ein. Die Verhaltensangaben der
              Nutzer (z.B. Mausbewegungen oder Abfragen) werden ausgewertet, um
              Menschen von Bots unterscheiden zu können. Dienstanbieter: Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; Website:{' '}
              <a
                href="https://www.google.com/recaptcha/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/recaptcha/
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
            <li>
              <strong>
                YouTube
                <br />
              </strong>
              Videos; Dienstanbieter: Google Ireland Limited, Gordon House,
              Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC,
              1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.youtube.com
              </a>
              ; Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              ; Privacy Shield (Gewährleistung Datenschutzniveau bei
              Verarbeitung von Daten in den USA):{' '}
              <a
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
              </a>
              ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </li>
            <li>
              <strong>Hotjar</strong>
              <br />
              Wir nutzen{' '}
              <a
                href="http://www.hotjar.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hotjar
              </a>
              , um die Bedürfnisse unserer Nutzer besser zu verstehen und das
              Angebot auf dieser Website zu optimieren. Mithilfe der Technologie
              von Hotjar bekommen wir ein besseres Verständnis von den
              Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer auf welchen
              Seiten verbringen, welche Links sie anklicken, was sie mögen und
              was nicht etc.) und das hilft uns, unser Angebot am Feedback
              unserer Nutzer auszurichten. Hotjar arbeitet mit Cookies und
              anderen Technologien, um Informationen über das Verhalten unserer
              Nutzer und über ihre Endgeräte zu sammeln (insbesondere IP Adresse
              des Geräts (wird nur in anonymisierter Form erfasst und
              gespeichert), Bildschirmgröße, Gerätetyp (Unique Device
              Identifiers), Informationen über den verwendeten Browser, Standort
              (nur Land), zum Anzeigen unserer Website bevorzugte Sprache).
              Hotjar speichert diese Informationen in einem pseudonymisierten
              Nutzerprofil. Die Informationen werden weder von Hotjar noch von
              uns zur Identifizierung einzelner Nutzer verwendet oder mit
              weiteren Daten über einzelne Nutzer zusammengeführt.
              Rechtsgrundlage ist Art. 6 abs. 1 S. 1 lit. f DSGVO. Weitere
              Informationen findest Du in Hotjars Datenschutzerklärung:{' '}
              <a
                href="https://www.hotjar.com/legal/policies/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hotjar.com/legal/policies/privacy
              </a>
              . Du kannst der Speicherung eines Nutzerprofils und von
              Informationen über Deinen Besuch auf unserer Website durch Hotjar
              sowie dem Setzen von Hotjar Tracking Cookies auf anderen Websites
              über diesen Link widersprechen:{' '}
              <a
                href="https://www.hotjar.com/legal/compliance/opt-out"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hotjar.com/legal/compliance/opt-out
              </a>
            </li>
          </ul>
          <h2 id="m12">Löschung von Daten</h2>
          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
            Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
            Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
            (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist
            oder sie für den Zweck nicht erforderlich sind).
          </p>
          <p>
            Sofern die Daten nicht gelöscht werden, weil sie für andere und
            gesetzlich zulässige Zwecke erforderlich sind, wird deren
            Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
            gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für
            Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt
            werden müssen oder deren Speicherung zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
            einer anderen natürlichen oder juristischen Person erforderlich ist.
          </p>
          <p>
            Weitere Hinweise zu der Löschung von personenbezogenen Daten können
            ferner im Rahmen der einzelnen Datenschutzhinweise dieser
            Datenschutzerklärung erfolgen.
          </p>
          <ul></ul>
          <h2 id="m15">
            Änderungen und Aktualisierungen der Datenschutzerklärung
          </h2>
          <p>
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <h2 id="m10">Rechte der betroffenen Personen</h2>
          <ul>
            <li>
              <strong>
                Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu
                betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht.
              </strong>
            </li>
            <li>
              <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
              Recht, erteilte Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
              Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
              werden und auf Auskunft über diese Daten sowie auf weitere
              Informationen und Kopie der Daten entsprechend den gesetzlichen
              Vorgaben.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
              den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
              betreffenden Daten oder die Berichtigung der Sie betreffenden
              unrichtigen Daten zu verlangen.
            </li>
            <li>
              <strong>
                Recht auf Löschung und Einschränkung der Verarbeitung:
              </strong>{' '}
              Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
              verlangen, dass Sie betreffende Daten unverzüglich gelöscht
              werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
              eine Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
              Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
              nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten oder deren
              Übermittlung an einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner
              nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde
              bei der zuständigen Aufsichtsbehörde einzureichen.
            </li>
          </ul>
          <h2 id="m42">Begriffsdefinitionen</h2>
          <p>
            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
            Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
            Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
            definiert. Die gesetzlichen Definitionen sind verbindlich. Die
            nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis
            dienen. Die Begriffe sind alphabetisch sortiert.
          </p>
          <ul clas="glossary">
            <li>
              <strong>Affiliate-Nachverfolgung:</strong> Bei der
              Affiliate-Nachverfolgung werden Links, mit deren Hilfe die
              verlinkenden Webseiten Nutzer zu Webseiten mit Produkt- oder
              sonstigen Angeboten verweisen, protokolliert. Die Betreiber der
              jeweils verlinkenden Webseiten können eine Provision erhalten,
              wenn Nutzer diesen so genannten Affiliate-Links folgen und
              anschließend die Angebote wahrnehmen (z.B., Waren kaufen oder
              Dienstleistungen in Anspruch nehmen). Hierzu ist es erforderlich,
              dass die Anbieter nachverfolgen können, ob Nutzer, die sich für
              bestimmte Angebote interessieren, diese anschließend auf die
              Veranlassung der Affiliate-Links wahrnehmen. Daher ist es für die
              Funktionsfähigkeit von Affiliate-Links erforderlich, dass sie um
              bestimmte Werte ergänzt, die ein Bestandteil des Links werden oder
              anderweitig, z.B. in einem Cookie, gespeichert werden. Zu den
              Werten gehören insbesondere die Ausgangswebseite (Referrer),
              Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf der
              sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen
              Angebotes, eine Online-Kennung des Nutzers, als auch
              nachverfolgungs-spezifische Werte wie, z.B. Werbemittel-ID,
              Partner-ID und Kategorisierungen
            </li>
            <li>
              <strong>Besuchsaktionsauswertung:</strong>{' '}
              „Besuchsaktionsauswertung“ (englisch „Conversion Tracking“)
              bezeichnet ein Verfahren, mit dem die Wirksamkeit von
              Marketingmaßnahmen festgestellt werden kann. Dazu wird im
              Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der
              Websites, auf denen die Marketingmaßnahmen erfolgen gespeichert
              und dann erneut auf der Zielwebsite abgerufen (z.B. können wir so
              nachvollziehen, ob die von uns auf anderen Webseiten geschalteten
              Anzeigen erfolgreich waren)
            </li>
            <li>
              <strong>IP-Masking:</strong> Als „IP-Masking” wird eine Methode
              bezeichnet, bei der das letzte Oktett, d.h. die letzten beiden
              Zahlen einer IP-Adresse gelöscht werden, damit die IP-Adresse
              nicht mehr der eindeutigen Identifizierung einer Person dienen
              können. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung
              von Verarbeitungsverfahren, insbesondere im Onlinemarketing.
            </li>
            <li>
              <strong>
                Interessenbasiertes und verhaltensbezogenes Marketing:
              </strong>{' '}
              Von interessens- und/oder verhaltensbezogenem Marketing spricht
              man, wenn potentielle Interesse von Nutzern an Anzeigen und
              sonstigen Inhalten möglichst genau vorbestimmt werden. Dies
              geschieht anhand von Angaben zu deren Vorverhalten (z.B.
              Aufsuchens und Verweilens auf bestimmten Webseiten, Kaufverhaltens
              oder Interaktion mit anderen Nutzern), die in einem sog. Profil
              gespeichert werden. Zu diesen Zwecken werden im Regelfall Cookies
              eingesetzt.
            </li>
            <li>
              <strong>Konversionsmessung:</strong> Konversionsmessung ist ein
              Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen
              festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf
              den Geräten der Nutzer innerhalb der Websites, auf denen die
              Marketingmaßnahmen erfolgen gespeichert und dann erneut auf der
              Zielwebsite abgerufen (z.B. können wir so nachvollziehen, ob die
              von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich
              waren).
            </li>
            <li>
              <strong>Profiling:</strong> Als „Profiling“ wird jede Art der
              automatisierten Verarbeitung personenbezogener Daten bezeichnet,
              die darin besteht, dass diese personenbezogenen Daten verwendet
              werden, um bestimmte persönliche Aspekte, die sich auf eine
              natürliche Person beziehen (je nach Art des Profilings gehören
              dazu Informationen betreffend das Alter, das Geschlecht,
              Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und
              deren Inhalten, Einkaufsverhalten, sozialen Interaktionen mit
              anderen Menschen), zu analysieren, zu bewerten oder um sie
              vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder
              Produkten, das Klickverhalten auf einer Webseite oder den
              Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
              und Web-Beacons eingesetzt.
            </li>
            <li>
              <strong>Reichweitenmessung :</strong> Die Reichweitenmessung (auch
              als Web Analytics bezeichnet) dient der Auswertung der
              Besucherströme eines Onlineangebotes und kann das Verhalten oder
              Interessen der Besucher an bestimmten Informationen, wie z.B.
              Inhalten von Webseiten umfassen. Mit Hilfe der Reichweitenanalyse
              können Websiteinhaber z.B. erkennen, zu welcher Zeit Besucher ihre
              Webseite besuchen und für welche Inhalte sie sich interessieren.
              Dadurch können sie z.B. die Inhalte der Webseite besser auf die
              Bedürfnisse ihrer Besucher optimieren. Zu Zwecken der
              Reichweitenanalyse werden häufig pseudonyme Cookies und
              Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
              so genauere Analysen zur Nutzung eines Onlineangebotes zu
              erhalten.
            </li>
            <li>
              <strong>Remarketing:</strong> Vom „Remarketing“, bzw.
              „Retargeting“ spricht man, wenn z.B. zu Werbezwecken vermerkt
              wird, für welche Produkte sich ein Nutzer auf einer Webseite
              interessiert hat, um den Nutzer auf anderen Webseiten an diese
              Produkte, z.B. in Werbeanzeigen, zu erinnern.
            </li>
            <li>
              <strong>Standortdaten:</strong> Daten, die den Standort des
              Endgeräts eines Endnutzers angeben.
            </li>
            <li>
              <strong>Tracking:</strong> Vom „Tracking“ spricht man, wenn das
              Verhalten von Nutzern über mehrere Onlineangebote hinweg
              nachvollzogen werden kann. Hierbei werden im Regelfall im Hinblick
              auf die genutzten Onlineangebote Verhaltens- und
              Interessensinformationen in Cookies oder auf Servern der Anbieter
              der Trackingtechnologien gespeichert (sog. Profiling). Diese
              Informationen können anschließend z.B. dazu eingesetzt werden, um
              den Nutzern Werbeanzeigen anzuzeigen, die mutmaßlich deren
              Interessen entsprechen.
            </li>
            <li>
              <strong>Verarbeitung:</strong> „Verarbeitung“ ist jeder mit oder
              ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder
              jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
              Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang
              mit Daten, sei es erheben, auswerten, speichern, übermitteln oder
              löschen.
            </li>
          </ul>
        </div>
        <p>
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer"
          >
            Erstellt mit Datenschutz-Generator.de von Dr. jur. Thomas Schwenke
          </a>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
