import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import useSound from 'use-sound';
import { Range } from 'react-range';

import '../App.css';

import night from '../icons/night.json';
import nightSound from '../sounds/summer-night.mp3';

function Night() {
  const [playNight, setPlayNight] = useState(false);
  const [opacity, setOpacity] = useState(0.5);
  const [loop, setLoop] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [showVolume, setShowVolume] = useState(false);
  const [play, { sound, isPlaying, stop }] = useSound(nightSound, {
    volume,
    loop: true,
  });

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return (
    <div className="sound-card">
      <Lottie
        onMouseEnter={() => {
          if (!isPlaying) setOpacity(1);
        }}
        onMouseLeave={() => {
          if (!isPlaying) setOpacity(0.5);
        }}
        style={{
          width: '75%',
          margin: '0 auto 10px auto',
          opacity: opacity,
          transition: 'opacity .2s',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isPlaying) {
            setOpacity(0.5);
            setShowVolume(false);
            sound.fade(volume, 0, 1000);
            setTimeout(stop, 1000);
          }
          if (!isPlaying) {
            setOpacity(1);
            setShowVolume(true);
            play();
            sound.fade(0, volume, 1000);
          }

          setPlayNight(!playNight);
          setLoop(!loop);
        }}
        loop={loop}
        play={playNight}
        animationData={night}
      />
      {showVolume && (
        <Range
          step={0.01}
          min={0}
          max={1}
          values={[volume]}
          onChange={(values) => setVolume(values[0])}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '2px',
                width: '75%',
                margin: '0 auto',
                backgroundColor: '#fff',
                borderRadius: '10px',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                outline: 'none',
                backgroundColor: '#fff',
                borderRadius: '50%',
              }}
            />
          )}
        />
      )}
    </div>
  );
}

export default Night;
